@import 'normalize.css';
@font-face {
  font-family: 'ALOTGutenbergA';
  src: url('fonts/ALOTGutenbergA/font.woff2') format('woff2'), url('fonts/ALOTGutenbergA/font.woff') format('woff');
}
@font-face {
  font-family: 'GT Sectra';
  font-style: normal;
  src: url('fonts/GT-Sectra/GT-Sectra-Regular.eot');
  src: url('fonts/GT-Sectra/GT-Sectra-Regular.woff2') format('woff2'), url('fonts/GT-Sectra/GT-Sectra-Regular.woff') format('woff'), url('fonts/GT-Sectra/GT-Sectra-Regular.woff') format('truetype');
}
@font-face {
  font-family: 'GT Sectra';
  font-style: italic;
  src: url('fonts/GT-Sectra/GT-Sectra-Regular-Italic.eot');
  src: url('fonts/GT-Sectra/GT-Sectra-Regular-Italic.woff2') format('woff2'), url('fonts/GT-Sectra/GT-Sectra-Regular-Italic.woff') format('woff'), url('fonts/GT-Sectra/GT-Sectra-Regular-Italic.woff') format('truetype');
}
*,
::after,
::before {
  box-sizing: border-box;
}
.default-transition {
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
html,
body {
  font-size: 100%;
  background-color: #FFFCF8;
}
@media screen and (min-width: 1100px) {
  html,
  body {
    height: 100%;
    overflow: hidden;
  }
}
body {
  font-family: 'GT Sectra', 'Time New Roman', sherif;
}
h1,
h2,
h3,
h4 {
  font-weight: normal;
}
@media screen and (min-width: 1100px) {
  #root,
  .app {
    height: 100%;
    width: 100vw;
  }
}
.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.row .col {
  position: relative;
  width: 100%;
  min-height: 1px;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
@media screen and (min-width: 1100px) {
  .row .col.two-fifth {
    width: 40%;
  }
  .row .col.three-fifth {
    width: 60%;
  }
}
@media screen and (min-width: 2200px) {
  .row .col.two-fifth {
    width: 20%;
  }
  .row .col.three-fifth {
    width: 80%;
  }
}
.row .col.with-divider:after,
.row .col-1.with-divider:after,
.row .col-10.with-divider:after,
.row .col-11.with-divider:after,
.row .col-12.with-divider:after,
.row .col-2.with-divider:after,
.row .col-3.with-divider:after,
.row .col-4.with-divider:after,
.row .col-5.with-divider:after,
.row .col-6.with-divider:after,
.row .col-7.with-divider:after,
.row .col-8.with-divider:after,
.row .col-9.with-divider:after,
.row .col-auto.with-divider:after,
.row .col-lg.with-divider:after,
.row .col-lg-1.with-divider:after,
.row .col-lg-10.with-divider:after,
.row .col-lg-11.with-divider:after,
.row .col-lg-12.with-divider:after,
.row .col-lg-2.with-divider:after,
.row .col-lg-3.with-divider:after,
.row .col-lg-4.with-divider:after,
.row .col-lg-5.with-divider:after,
.row .col-lg-6.with-divider:after,
.row .col-lg-7.with-divider:after,
.row .col-lg-8.with-divider:after,
.row .col-lg-9.with-divider:after,
.row .col-lg-auto.with-divider:after,
.row .col-md.with-divider:after,
.row .col-md-1.with-divider:after,
.row .col-md-10.with-divider:after,
.row .col-md-11.with-divider:after,
.row .col-md-12.with-divider:after,
.row .col-md-2.with-divider:after,
.row .col-md-3.with-divider:after,
.row .col-md-4.with-divider:after,
.row .col-md-5.with-divider:after,
.row .col-md-6.with-divider:after,
.row .col-md-7.with-divider:after,
.row .col-md-8.with-divider:after,
.row .col-md-9.with-divider:after,
.row .col-md-auto.with-divider:after,
.row .col-sm.with-divider:after,
.row .col-sm-1.with-divider:after,
.row .col-sm-10.with-divider:after,
.row .col-sm-11.with-divider:after,
.row .col-sm-12.with-divider:after,
.row .col-sm-2.with-divider:after,
.row .col-sm-3.with-divider:after,
.row .col-sm-4.with-divider:after,
.row .col-sm-5.with-divider:after,
.row .col-sm-6.with-divider:after,
.row .col-sm-7.with-divider:after,
.row .col-sm-8.with-divider:after,
.row .col-sm-9.with-divider:after,
.row .col-sm-auto.with-divider:after,
.row .col-xl.with-divider:after,
.row .col-xl-1.with-divider:after,
.row .col-xl-10.with-divider:after,
.row .col-xl-11.with-divider:after,
.row .col-xl-12.with-divider:after,
.row .col-xl-2.with-divider:after,
.row .col-xl-3.with-divider:after,
.row .col-xl-4.with-divider:after,
.row .col-xl-5.with-divider:after,
.row .col-xl-6.with-divider:after,
.row .col-xl-7.with-divider:after,
.row .col-xl-8.with-divider:after,
.row .col-xl-9.with-divider:after,
.row .col-xl-auto.with-divider:after {
  content: ' ';
  display: block;
  position: absolute;
  right: -9px;
  top: 0;
  bottom: 0;
  width: 15px;
  background-image: url(../img/divider-line-white.svg);
  background-repeat: repeat-y;
  z-index: 10;
  pointer-events: none;
}
.firstletter {
  position: relative;
  top: 0.6rem;
  font-family: 'ALOTGutenbergA';
  font-size: 150%;
}
.color-red {
  color: #E75E3E;
}
.app-header {
  position: relative;
  z-index: 10;
}
.sub .app-header.mobile-header {
  display: none;
}
@media screen and (min-width: 1100px) {
  .app-header.mobile-header {
    display: none;
  }
}
.app-logo {
  position: absolute;
  top: 50px;
  left: 25px;
}
@media screen and (min-width: 1100px) {
  .app-logo {
    top: 70px;
    left: 100px;
  }
}
.app-logo .logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
}
.large.app-logo .logo {
  top: -20px;
  width: 57px;
}
.app-logo .text {
  position: absolute;
  left: 28px;
  top: 11px;
  width: 185px;
}
.large.app-logo .text {
  opacity: 0;
  pointer-events: none;
}
.app-logo .logo,
.app-logo .text {
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.app-container {
  position: absolute;
  height: 100%;
  overflow-y: auto;
  -webkit-transition: 300ms ease-in-out;
  -o-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}
.app-container .app-front {
  position: relative;
  background: #554B3C;
}
@media screen and (min-width: 1100px) {
  .app-container .app-front {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
}
.app-container .app-front .app-content-inner {
  margin-top: 150px;
  padding-top: 0;
  color: #fff;
  z-index: 2;
}
@media screen and (min-width: 1100px) {
  .app-container .app-front .app-content-inner {
    width: 100%;
    max-width: 1200px;
    max-height: inherit;
    margin: 80px 70px;
    padding-top: 150px;
  }
}
.app-container .app-front .app-content-inner h1 {
  margin-top: -35px;
  font-size: 4rem;
  font-weight: 500;
  line-height: 0.95;
}
@media screen and (max-width: 1100px) {
  .app-container .app-front .app-content-inner h1 {
    font-size: 2rem;
  }
}
.app-container .app-front .app-content-inner p {
  font-size: 1.125rem;
}
.app-container .app-front .app-content-inner p a {
  color: #FF5530;
}
.app-container .app-front.page-view .app-content-inner .page-view-content {
  margin-bottom: 50px;
}
.app-container .app-front.page-view .app-content-inner img {
  max-width: 100%;
}
.app-container .app-front .front-boxes {
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
}
.app-container .app-front .front-boxes .box {
  display: flex;
  height: 200px;
  width: 100%;
  margin: 0 0 28px 0;
  padding: 22px;
  background-color: rgba(255, 255, 255, 0.5);
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.125rem;
  align-items: flex-end;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.app-container .app-front .front-boxes .box:hover {
  color: #FF5530;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
@media screen and (min-width: 700px) {
  .app-container .app-front .front-boxes .box {
    margin: 0 28px 28px 0;
  }
  .app-container .app-front .front-boxes .box.box-small {
    width: 30%;
  }
  .app-container .app-front .front-boxes .box.box-medium {
    width: 50%;
  }
  .app-container .app-front .front-boxes .box.box-large {
    width: 60%;
  }
}
.sub .app-container.menu-wrapper {
  margin-left: -100vw;
}
.app-container.main-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  opacity: 0;
  pointer-events: none;
}
@media screen and (min-width: 1100px) {
  .app-container.main-wrapper {
    left: 100vw;
    opacity: 1;
  }
}
.sub .app-container.main-wrapper {
  left: 0;
  opacity: 1;
  pointer-events: all;
}
@media screen and (min-width: 1100px) {
  .app-content {
    display: flex;
    height: 100%;
  }
}
.app-content .app-content-inner {
  position: relative;
  padding: 140px 25px 25px 25px;
  z-index: 10;
}
@media screen and (min-width: 1100px) {
  .app-content .app-content-inner {
    max-height: 560px;
    margin-left: 100px;
    padding: 0;
    align-self: center;
  }
  .app-content .app-content-inner.align-top {
    align-self: start;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.app-content.menu-column {
  background: #503D32;
}
@media screen and (max-width: 1100px) {
  .app-content.menu-column {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    z-index: 110;
  }
  .menu-visible .app-content.menu-column {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@media screen and (min-width: 1100px) {
  .app-content.menu-column {
    display: flex;
    height: 100%;
  }
  .app-content.menu-column:after {
    content: ' ';
    display: block;
    position: absolute;
    right: -9px;
    top: 0;
    bottom: 0;
    width: 15px;
    background-image: url(../img/divider-line-brown.svg);
    background-repeat: repeat-y;
    z-index: 10;
    pointer-events: none;
    -webkit-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
  }
}
@media screen and (min-width: 1100px) {
  .front .app-content.menu-column {
    background: #fff;
  }
}
.front .app-content.menu-column:after {
  background-image: url(../img/divider-line-white.svg);
}
@media screen and (min-width: 1100px) {
  .scroll-wrapper {
    height: 100vh;
    max-width: 100vw;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-wrapper .scroll-container {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
@media screen and (max-width: 1100px) {
  .scroll-wrapper .scroll-container .chapters-wrapper {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 50px;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-wrapper .scroll-container .chapters-wrapper {
    display: flex;
    height: 100%;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-wrapper .scroll-container .chapters-wrapper .chapter.first {
    padding-left: 100px !important;
  }
}
.scroll-wrapper .scroll-container.page-content .timeline-ticks-vertical {
  background-image: none;
}
.scroll-section {
  position: relative;
  box-sizing: border-box;
}
@media screen and (min-width: 1100px) {
  .scroll-section {
    height: 100%;
    width: 1082px;
  }
}
.scroll-section.chapter {
  position: relative;
  color: #F7F5EB;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter {
    height: 100% !important;
    padding: 0 100px;
  }
}
.scroll-section.chapter.chapter-info {
  background: url(../img/chapter-back.jpg);
  background-size: cover;
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter.chapter-info {
    padding: 100px 0 0 25px;
  }
  .scroll-section.chapter.chapter-info .app-content-inner {
    padding-left: 40px;
    padding-top: 80px;
  }
  .scroll-section.chapter.chapter-info.with-image {
    padding-bottom: 300px;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.chapter-info {
    padding-right: 300px;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.chapter-info .app-content-inner {
    max-height: 750px;
    margin-left: 70px;
  }
}
.scroll-section.chapter.chapter-info .chapter-info-image {
  position: absolute;
  bottom: 0;
  right: 5%;
  text-align: right;
  pointer-events: none;
}
.scroll-section.chapter.chapter-info .chapter-info-image img {
  display: block;
  max-height: 250px;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.chapter-info .chapter-info-image img {
    max-height: 550px;
  }
}
.scroll-section.chapter.chapter-intro p {
  font-size: 1.125rem;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.chapter-intro p {
    font-size: 2.375rem;
  }
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter.chapter-intro .app-content-inner {
    padding: 40px 50px 40px 80px;
  }
}
.scroll-section.chapter.chapter-intro .chapter-background {
  background-image: none;
}
.scroll-section.chapter .chapter-image {
  pointer-events: none;
}
.scroll-section.chapter .chapter-image.position-uppi {
  position: absolute;
  top: 0;
  right: 20%;
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter .chapter-image.position-uppi {
    display: none;
  }
}
.scroll-section.chapter .chapter-image.position-nidri {
  position: absolute;
  bottom: 0;
  left: 20%;
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter .chapter-image.position-nidri {
    position: relative;
  }
}
.scroll-section.chapter .chapter-image.image-frame img {
  border-radius: 4px;
  box-shadow: 0px 2px 14px #0000007d;
}
.scroll-section.chapter .chapter-image img {
  display: block;
  max-width: 500px;
  max-height: 30vh;
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter .chapter-image img {
    max-width: 40vw;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter .app-content-inner {
    height: 80%;
    max-height: 600px;
  }
}
.scroll-section.chapter .app-content-inner .chapter-nav-buttons {
  position: absolute;
  left: -150px;
  top: 85px;
  width: 130px;
  text-align: right;
}
.scroll-section.chapter .app-content-inner .chapter-nav-buttons a {
  display: inline-block;
  margin-left: 5px;
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter .app-content-inner .chapter-nav-buttons {
    left: -58px;
    top: 90px;
    -webkit-transform: rotate(90deg) scale(0.7);
    -moz-transform: rotate(90deg) scale(0.7);
    -ms-transform: rotate(90deg) scale(0.7);
    transform: rotate(90deg) scale(0.7);
  }
  .scroll-section.chapter .app-content-inner .chapter-nav-buttons a {
    margin-left: 15px;
  }
}
.scroll-section.chapter .chapter-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../img/timeline-ticks-vertical.svg);
  background-position: 18px 45px;
  background-repeat: repeat-y;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter .chapter-background {
    background-image: none;
  }
  .scroll-section.chapter .chapter-background:not(.first-chapter) {
    left: -100px;
  }
}
.page-content .scroll-section.chapter .chapter-background {
  background-image: none;
}
.scroll-section.chapter .chapter-divider-narrow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 80px;
  width: 40px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter .chapter-divider-narrow {
    display: none;
  }
}
.scroll-section.chapter.last .chapter-divider-narrow {
  right: -20px;
}
.page-content .scroll-section.chapter.last .chapter-divider-narrow {
  display: none;
}
.scroll-section.chapter.timeline-item {
  padding: 20px 20px 20px 75px;
}
.page-content .scroll-section.chapter.timeline-item {
  padding-left: 30px;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.timeline-item {
    padding: 0;
  }
}
.scroll-section.chapter.timeline-item .app-content-inner {
  position: relative;
  padding: 0;
  z-index: 10;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.timeline-item .app-content-inner {
    height: 80%;
    max-height: 600px;
    padding-top: 15%;
    margin-left: 0;
    background-image: url(../img/timeline-ticks.svg);
    background-position: top left;
    background-repeat: repeat-x;
  }
  .page-content .scroll-section.chapter.timeline-item .app-content-inner {
    padding-top: 0;
    background-image: none;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.timeline-item .app-content-inner h2 {
    margin-left: -20px;
  }
}
.scroll-section.chapter.timeline-item .app-content-inner .date {
  position: absolute;
  font-size: 1.125rem;
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter.timeline-item .app-content-inner .date {
    left: -26px;
    width: 60px;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.timeline-item .app-content-inner .date {
    top: -40px;
    font-size: 1.4375rem;
  }
}
.scroll-section.chapter.timeline-item .app-content-inner .date span {
  margin-left: -50%;
}
.scroll-section.chapter.timeline-item .app-content-inner .date:after {
  content: " ";
  display: block;
  position: absolute;
  background: #ffffffb0;
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter.timeline-item .app-content-inner .date:after {
    top: 25px;
    left: -31px;
    height: 2px;
    width: 50px;
  }
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.timeline-item .app-content-inner .date:after {
    top: 40px;
    left: 0;
    height: 190px;
    width: 2px;
  }
}
.scroll-section.chapter.timeline-item .app-content-inner p.innskot {
  width: 60%;
  min-width: 500px;
  max-width: 800px;
  font-size: 1.125rem;
  -webkit-transform: translate(30%, 50px);
  -moz-transform: translate(30%, 50px);
  -ms-transform: translate(30%, 50px);
  transform: translate(30%, 50px);
}
@media screen and (max-width: 1100px) {
  .scroll-section.chapter.timeline-item .app-content-inner p.innskot {
    margin-left: 20px;
    padding-left: 20px;
    min-width: auto;
    width: 100%;
    font-size: 0.875rem;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.scroll-section.chapter .button-list {
  margin-top: 50px;
}
.scroll-section.chapter .button-list .info-button {
  display: inline-block;
  margin: 0 10px 10px 0;
}
.scroll-section.chapter .info-button {
  padding: 13px 16px;
  background-color: #FFFFFF;
  border: 1px solid #1E3250;
  box-sizing: border-box;
  border-radius: 120px;
  font-size: 1.125rem;
  color: #1E3250;
  text-decoration: none;
  text-align: left;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.scroll-section.chapter .info-button:hover {
  background: #554B3C;
  color: #fff;
}
.scroll-section.chapter .info-button:focus {
  outline: none;
}
.scroll-section.chapter .info-button.icon {
  padding-left: 35px;
  background-repeat: no-repeat;
}
.scroll-section.chapter .info-button.icon-arrows {
  background-image: url(../img/button-icon-arrows.svg);
  background-position: 16px 23px;
}
.scroll-section.chapter.question {
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.scroll-section.chapter.question .chapter-divider {
  left: 100%;
  right: auto;
  margin-left: -5px;
  width: 300px;
  background-position: left;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter.question .app-content-inner {
    max-height: 800px;
  }
}
.scroll-section.chapter.question .question-title {
  margin-bottom: 100px;
}
.scroll-section.chapter.question .answer {
  max-width: 480px;
  padding: 20px;
  color: #554B3C;
  font-size: 1.375rem;
  background-image: url(../img/answer-back.svg);
  background-size: 100% 100%;
}
.scroll-section.chapter.question .info-button {
  background-color: transparent;
  border-color: #F2F7FB;
  color: #fff;
}
.scroll-section.chapter.question .info-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.scroll-section.chapter.question .info-button.active {
  color: #554B3C;
  background-color: #FFFCF8;
  border-color: #54532C;
  box-shadow: -2px 4px 6px rgba(0, 0, 0, 0.2);
}
.scroll-section.chapter .quotation-mark {
  font-size: 7.5rem;
  font-family: 'ALOTGutenbergA';
  line-height: 0;
}
.scroll-section.chapter .quotation-mark.first {
  position: absolute;
  left: -52px;
}
.scroll-section.chapter .quotation-mark.last {
  position: relative;
  top: 40px;
}
.scroll-section.chapter h2 {
  margin-top: 0;
  font-size: 2rem;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter h2 {
    max-width: 80%;
    font-size: 4rem;
  }
  .hide-date.scroll-section.chapter h2 {
    font-size: 2.5rem;
  }
}
.scroll-section.chapter h2 .capital-letter {
  position: relative;
  top: 10px;
  font-size: 3rem;
  font-family: 'ALOTGutenbergA';
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter h2 .capital-letter {
    top: 24px;
    font-size: 7.5rem;
  }
  .hide-date.scroll-section.chapter h2 .capital-letter {
    top: 15px;
    font-size: 5.625rem;
  }
}
.scroll-section.chapter h2 small {
  font-size: 1.375rem;
}
.scroll-section.chapter p {
  position: relative;
  line-height: 128%;
  font-size: 1.125rem;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter p {
    max-width: 80%;
    font-size: 1.375rem;
  }
}
.scroll-section.chapter .chapter-divider {
  display: none;
  pointer-events: none;
}
.scroll-section.chapter .chapter-divider.desktop {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 400px;
  max-height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
.scroll-section.chapter .chapter-divider.mobile {
  display: block;
  position: absolute;
  top: 0;
  bottom: -5px;
  right: -5px;
  left: -5px;
  max-width: 110%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  pointer-events: none;
}
@media screen and (min-width: 1100px) {
  .scroll-section.chapter .chapter-divider.desktop {
    display: block;
  }
  .scroll-section.chapter .chapter-divider.mobile {
    display: none;
  }
}
ul.front-menu {
  padding-left: 0;
}
ul.front-menu li {
  margin-bottom: 2.5rem;
  list-style: none;
}
ul.front-menu li a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  font-size: 2rem;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
@media screen and (min-width: 1100px) {
  .front ul.front-menu li a {
    color: #503D32;
  }
}
ul.front-menu li a.active {
  font-size: 4rem;
  -webkit-transform: translateX(16px);
  -moz-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
ul.front-menu li a.active:hover {
  color: #fff;
}
ul.front-menu li a:hover {
  color: #FF5530;
  -webkit-transform: translateX(16px);
  -moz-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
.site-footer {
  position: absolute;
  left: 100px;
  bottom: 40px;
  min-width: 285px;
  color: #fff;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.front .site-footer {
  color: #503D32;
}
.site-footer.mobile-footer {
  display: block;
  position: relative;
  left: auto;
  bottom: auto;
  width: 100%;
  min-width: auto;
  padding: 25px;
  color: #fff;
  background-color: #503D32;
}
.front .site-footer.mobile-footer {
  color: #503D32;
  background-color: #fff;
}
@media screen and (min-width: 1100px) {
  .site-footer.mobile-footer {
    display: none;
  }
}
.site-footer .logo {
  position: relative;
  height: 39px;
  margin-bottom: 8px;
}
.site-footer .logo img {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.site-footer .logo img.dark {
  opacity: 0;
}
.front .site-footer .logo img.dark {
  opacity: 1;
}
.site-footer .logo img.light {
  opacity: 1;
}
.front .site-footer .logo img.light {
  opacity: 0;
}
.site-footer p {
  margin-bottom: 0.6rem;
  font-size: 0.875rem;
}
.site-footer p a {
  color: inherit;
}
.site-footer p.larger {
  font-size: 1rem;
}
.link-list {
  position: relative;
  z-index: 10;
}
.link-list a {
  color: #503D32;
  text-decoration: none;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.link-list a h1 {
  margin: 0 0 2rem 0;
  font-size: 2rem;
  font-weight: normal;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.link-list a small {
  font-size: 0.8125rem;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.front-menu-button,
.front-menu-button-mobile,
.chapter-menu-button {
  position: absolute;
  top: 54px;
  background-size: cover;
  z-index: 100;
}
.front-menu-button span,
.front-menu-button-mobile span,
.chapter-menu-button span {
  display: none;
}
.front-menu-button {
  left: 25px;
  width: 42px;
  height: 57px;
  background-image: url(../img/logo-white.svg);
  mix-blend-mode: difference;
}
@media screen and (min-width: 1100px) {
  .front-menu-button {
    left: 100px;
  }
}
.front-menu-button-mobile {
  left: 25px;
  width: 46px;
  height: 46px;
  background-image: url(../img/chapter-prev-button.svg);
}
@media screen and (min-width: 1100px) {
  .front-menu-button-mobile {
    display: none;
  }
}
.chapter-menu-button {
  right: 54px;
  width: 46px;
  height: 46px;
  background-image: url(../img/icon-chapter-button.svg);
  z-index: 120;
}
@media screen and (max-width: 1100px) {
  .chapter-menu-button {
    position: fixed;
    right: 20px;
  }
}
@media screen and (min-width: 1100px) {
  .front .chapter-menu-button {
    display: none;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(107, 107, 107, 0.44);
  z-index: 100;
  opacity: 0;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.modal-overlay.initialized {
  opacity: 1;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  background: #fff;
  z-index: 200;
  color: #503D32;
  overflow-y: auto;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
@media screen and (min-width: 1100px) {
  .modal {
    width: 80%;
    min-width: 500px;
    max-width: 1200px;
  }
}
.modal.initialized {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.modal .close-button {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 48px;
  height: 48px;
  border: none;
  background: transparent;
}
.modal .close-button:focus {
  outline: none;
}
.modal .modal-content {
  padding: 15px 20px;
}
@media screen and (min-width: 1100px) {
  .modal .modal-content {
    padding: 15px 70px;
  }
}
@media screen and (max-width: 1100px) {
  .modal iframe {
    max-height: 300px;
  }
}
.modal h3 {
  margin-top: 0;
  font-size: 1.875rem;
}
.modal h3 small {
  font-size: 1.375rem;
}
@media screen and (min-width: 1100px) {
  .modal h3 {
    font-size: 3rem;
  }
}
.modal p {
  font-size: 1.125rem;
}
.modal .modal-image {
  background-color: #F7F5EB;
}
.modal .modal-image img {
  max-width: 100%;
}
@media screen and (max-width: 1100px) {
  .indiana-scroll-container {
    overflow-x: hidden;
  }
}
.chapter-hover-image {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  opacity: 0;
  pointer-events: none;
}
.chapter-hover-image.visible {
  opacity: 1;
}
.chapter-hover-image img {
  display: block;
  float: right;
  max-width: 80%;
}
